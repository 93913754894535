.contact-p {
    color: #b2bec3;
    font-size: large;
    margin: 20px;
}

.contact-methods {
    margin-top: 50px;
}

.discord.massive.link.icon {
    color: #8A9CFE;
}

.mail.massive.link.icon {
    color: #dfe6e9;
}