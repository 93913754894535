.body {
    position: fixed !important;
    top: 40% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
}

h3.fields {
    color: white;
}

.ui.button.addUser:not(.icon)>.icon:not(.button):not(.dropdown){
    margin: 0px;
    padding-left: 10px;
    font-size: 13px;
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.ui.button.login:not(.icon)>.icon:not(.button):not(.dropdown){
    margin: 0px;
    padding-left: 10px;
    font-size: 13px;
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.ui.button.forgotPassword:not(.icon)>.icon:not(.button):not(.dropdown){
    margin: 0px;
    padding-left: 10px;
    font-size: 13px;
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.buttonText {
    font-size: 14px;
}

.ui.menu:not(.vertical) .item>.button {
    margin-left: 5px;
    margin-right: 5px;
}

.ui.inverted.button.addUser {
    background: transparent none;
    -webkit-box-shadow: 0 0 0 2px #63D790 inset !important;
    box-shadow: 0 0 0 2px #63D790 inset !important;
    color: #63D790;
    text-shadow: none !important;
}

.ui.inverted.button.addUser:hover {
    background: #63D790;
    -webkit-box-shadow: 0 0 0 2px #63D790 inset !important;
    box-shadow: 0 0 0 2px #63D790 inset !important;
    color: white;
}

.ui.inverted.button.addUser:focus {
    background: none;
    -webkit-box-shadow: 0 0 0 2px #63D790 inset !important;
    box-shadow: 0 0 0 2px #63D790 inset !important;
    color: #63D790;
}

.ui.inverted.button.login {
    background: transparent none;
    -webkit-box-shadow: 0 0 0 2px #1EBAC4 inset !important;
    box-shadow: 0 0 0 2px #1EBAC4 inset !important;
    color: #1EBAC4;
    text-shadow: none !important;
}

.ui.inverted.button.login:hover {
    background: #1EBAC4;
    -webkit-box-shadow: 0 0 0 2px #1EBAC4 inset !important;
    box-shadow: 0 0 0 2px #1EBAC4 inset !important;
    color: white;
}

.ui.inverted.button.login:focus {
    background: none;
    -webkit-box-shadow: 0 0 0 2px #1EBAC4 inset !important;
    box-shadow: 0 0 0 2px #1EBAC4 inset !important;
    color: #1EBAC4;
}